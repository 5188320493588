import { useState } from "react";

export default function BugReport({ user }) {
    const [state, setState] = useState({ title: "", desc: "" });
    const [msg, setMsg] = useState({ status: "", content: "" });
    const [disabled, setDisabled] = useState(false);

    const UpdateState = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        });
    };

    const SubmitForm = async () => {
        if (!state.title || !state.desc) {
            setMsg({ status: "fail", content: "Please fill in all fields." });
        } else {
            setMsg({ status: "", content: "" });
            const response = await fetch('https://oldapi.caigo.org/api/dash/webhook/new-features', {
                method: "POST",
                credentials: 'include',
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify({
                    title: state.title,
                    description: state.desc,
                    user: user.name
                })
            });
            const body = await response.json();
            if (body.success) {
                setMsg({ status: "success", content: "Message successfully delivered." });
            } else {
                setMsg({ status: "fail", content: "Failed to send a message." });
            }

            setDisabled(true);
            setTimeout(() => setDisabled(false), 120000);
        }
    };
    return (
        <div className="flex flex-col w-full h-[calc(100vh-60px)] bg-[#151A26]">
            <div className="w-auto h-auto text-white bg-[#121620] p-5 m-5 rounded-lg">
                <h1 className="font-bold text-lg">Suggest a Feature</h1>
                <p className="mt-3">We don't offer something you want? Write a siggestion!</p>
            </div>
            <div className="bg-[#121620] max-w-[25rem] min-w-fit p-5 m-5">
                <div>
                    <p className="text-white">Title</p>
                    <input className="w-full h-10 rounded bg-[#1E2535] mt-2 pl-2 text-white" onChange={UpdateState} name="title" value={state["title"]} />
                </div>
                <div>
                    <p className="text-white mt-8">Description</p>
                    <textarea className="w-full h-32 rounded bg-[#1E2535] mt-2 resize-none p-2 text-white" onChange={UpdateState} name="desc" value={state["desc"]} />
                </div>
                {
                    disabled
                        ?
                        <button className="rounded text-white bg-[#1E2535] px-8 py-2 mt-4 cursor-default">Submit</button>
                        :
                        <button onClick={SubmitForm} className="rounded text-white bg-[#1E2535] px-8 py-2 mt-4 hover:bg-gray-700 transition">Submit</button>
                }

                {
                    msg.content && <p className={msg.status === "success" ? "text-green-500 mt-4" : "text-red-500 mt-4"}>{msg.content}</p>
                }
            </div>

        </div>
    );
}
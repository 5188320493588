import WindowsSVG from "./img/windows.svg";
import AppleSVG from "./img/apple.svg";

import BUILDwin from "../build/CopTools_Setup_2.2.0.exe";
import BUILDmac from "../build/CopTools-2.2.0.dmg";

export default function Download() {

    return (
        <div className="flex flex-col w-full h-[calc(100vh-60px)] bg-[#151A26]">
            <div className="w-auto h-auto text-white bg-[#121620] p-5 m-5 rounded-lg">
                <h1 className="font-bold text-lg">Instructions</h1>
                <p className="mt-3">To download CopTools desktop application simply click on desired operating system icon.</p>

                <p>{"We would suggest using auto-updates once you have CopTools installed on your computer. (coming soon)"}</p>
            </div>

            <div className="w-auto h-auto text-white bg-[#121620] p-5 m-5 rounded-lg">
                <h1 className="font-bold text-lg">Windows</h1>
                <button className="flex items-center gap-3 border border-white rounded-lg py-2 px-5 hover:bg-gray-600 transition mt-3">
                    <a href={BUILDwin} download>Download</a>
                    <img src={WindowsSVG} className="w-8" />
                </button>
            </div>
            <div className="w-auto h-auto text-white bg-[#121620] p-5 m-5 rounded-lg">
                <h1 className="font-bold text-lg">Mac</h1>
                <button className="flex items-center gap-3 border border-white rounded-lg py-2 px-5 hover:bg-gray-600 transition mt-3">
                    <a href={BUILDmac} download>Download</a>
                    <img src={AppleSVG} className="w-8 fill-white" fill="white" />
                </button>
            </div>
        </div>
    );
}
import { Outlet } from 'react-router-dom';

import Sidebar from './components/Sidebar';
import Topbar from './components/Topbar';

export default function ApplicationFrame({ user, selectedServer, setSelectedServer }) {
    return (
        <div className="h-screen">
            <Topbar user={user} />

            <div className="flex">
                <Sidebar user={user} selectedServer={selectedServer} setSelectedServer={setSelectedServer} />

                <div className="grow overflow-auto bg-[#151A26]">
                    <Outlet />
                </div>
            </div>
        </div>
    );
}
import { useEffect, useState } from "react";

import LogoPNG from "./img/logoShort.svg";

export default function Login({ isLoggedIn, setIsLoggedIn, setUser }) {
    const [message, setMessage] = useState("");
    useEffect(() => {
        if (!isLoggedIn) {
            fetchData();
        }
    }, []);

    const fetchData = async () => {
        const authToken = getCookie('auth-token');

        const response = await fetch('https://oldapi.caigo.org/api/dash/auth/check',
            {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': authToken,
                },
            });

        const data = await response.json();

        if (data.success) {
            setUser(data.user);
            setMessage("");
            setIsLoggedIn(true);
        } else {
            if (data.message === "You don't have access to CopTools") {
                setMessage(data.message);
            } else {
                setMessage("");
            }

            setIsLoggedIn(false);
        }
    };

    function getCookie(name) {
        const cookieString = document.cookie;
        const cookies = cookieString.split('; ');

        for (const cookie of cookies) {
            const [cookieName, cookieValue] = cookie.split('=');
            if (cookieName === name) {
                return cookieValue;
            }
        }

        return null;
    }

    return (
        <div className="bg-[#151A26] w-screen h-screen relative">
            <div className="w-auto sm:w-[28rem] bg-[#121620] text-white absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 p-10 rounded-xl">

                <div className="flex flex-col items-center gap-5">
                    <img src={LogoPNG} alt="" />
                    <div className="">Welcome to CopTools Dashboard!</div>
                    <div className="">Please login with Discord to get started.</div>
                    {
                        message && <div className="mx-3 my-5 text-red-500">{message}</div>
                    }

                    {/* <a className="bg-white rounded-lg w-full text-[#0f1218] font-medium text-center py-3 hover:scale-105 transition" href="https://discord.com/api/oauth2/authorize?client_id=1038397716235042857&redirect_uri=http%3A%2F%2Flocalhost%3A4000%2Fapi%2Fdash%2Fauth%2Fdiscord&response_type=code&scope=identify%20email%20guilds%20guilds.join%20guilds.members.read">Login</a> */}
                    <a className="bg-white rounded-lg w-full text-[#0f1218] font-medium text-center py-3 hover:scale-105 transition" href="https://discord.com/api/oauth2/authorize?client_id=1038397716235042857&redirect_uri=https%3A%2F%2Foldapi.caigo.org%2Fapi%2Fdash%2Fauth%2Fdiscord&response_type=code&scope=identify%20email%20guilds%20guilds.join%20guilds.members.read">Login</a>
                    <div className="text-center">Not a member yet? Click <a href="https://discord.gg/XgxETDZES5" target="_blank" className="p-0 m-0 text-[#7B01FC] underline">here</a> to apply with your group</div>
                </div>
            </div>
        </div>
    );
}
import { useState, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import { useLocalStorage } from "./pages/util/useLocalStorage";

import ApplicationFrame from "./components/ApplicationFrame";
import Login from "./components/Login";

import Download from "./pages/Download";
import DeviceManagement from "./pages/DeviceManagement";
import BugReport from "./pages/BugReport";
import NewFeatures from "./pages/NewFeatures";
import Appearance from "./pages/Appearance";
import Roles from "./pages/Roles";


export default function App() {
  const [login, setLogin] = useState(false);
  const [user, setUser] = useState({});
  const [server, setServer, clearLocalStorage] = useLocalStorage("selectedServer");
  const [selectedServer, setSelectServer] = useState(server() || { serverName: '', serverID: '' });

  const setSelectedServer = (s) => {
    setSelectServer(s);
  };

  useEffect(() => {
    setServer(selectedServer);
  }, [setServer, selectedServer]);

  if (!login) {
    return (<Login
      isLoggedIn={login}
      setIsLoggedIn={setLogin}
      setUser={setUser}
      setSelectedServer={setSelectedServer}
    />);
  }

  return (
    <div className="">
      <Routes>
        <Route path="/" element={<ApplicationFrame user={user} setLogin={setLogin} selectedServer={selectedServer} setSelectedServer={setSelectedServer} />}>
          <Route index element={<Download />} />
          <Route path="/devices" element={<DeviceManagement />} />
          <Route path="/bug_report" element={<BugReport user={user} />} />
          <Route path="/new_features" element={<NewFeatures user={user} />} />
          <Route path="/admin_app_appearance" element={
            <ProtectedRoute user={user}>
              <Appearance selectedServer={selectedServer} />
            </ProtectedRoute>
          } />
          <Route path="/admin_roles" element={
            <ProtectedRoute user={user}>
              <Roles user={user} selectedServer={selectedServer} />
            </ProtectedRoute>
          } />
          <Route path="*" element={<Download />} />
        </Route>
      </Routes>
    </div>
  );
}

const ProtectedRoute = ({ user, children }) => {
  if (user.adminInfo.length === 0) {
    return <Navigate to="/" replace />;
  }

  return children;
};
import logo from '../img/logo.svg'

export default function Topbar({user}) {

    return (
        <div className="w-screen h-[60px] bg-[#121620] border-b border-gray-800 flex items-center px-[12px] gap-5 justify-between overflow-x-scroll overflow-y-hidden">
            {/* Branding */}
            <img src={logo} alt="logo" className='flex' />

            {/* User */}
            <div className='flex items-center gap-4'>
                <div className='text-right'>
                    <p className='text-[14px] font-medium text-white'>{user.name}</p>
                </div>

                <img className='h-[32px] w-[32px] rounded-full' src={user.picture} alt="ppf" />
            </div>
        </div>
    )
}
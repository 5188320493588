import RolesComp from './roles/RolesComp'

export default function Roles({ user, selectedServer }) {

    return (
        <div className="flex flex-col w-full h-[calc(100vh-60px)] bg-[#151A26] overflow-x-scroll">
            <RolesComp selectedServer={selectedServer} type={""} title={"server"} user={user} />
            <RolesComp selectedServer={selectedServer} type={"/admin"} title={"admin"} user={user} />
        </div>
    )
}
import { useEffect, useState, Fragment } from "react";
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';

export default function Appearance({ selectedServer }) {
    const [logoLink, setLogoLink] = useState("");
    const [themes, setThemes] = useState([]);
    const [theme, setTheme] = useState(themes[0] || {});
    const [newLink, setNewLink] = useState(false);
    const [link, setLink] = useState("");

    useEffect(() => {
        getInfo();
    }, []);

    const getInfo = async () => {
        const response = await fetch('https://oldapi.caigo.org/api/dash/appearance/get', {
            method: "POST",
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                serverID: selectedServer.serverID
            })
        });

        const data = await response.json();
        console.log(data);

        if (data.success) {
            setLogoLink(data.info.logo);
            setThemes(data.info.themes);
            const themeToSet = data.info.themes.find(t => t.name === data.info.theme.name);
            setTheme(themeToSet);
        }
    };

    const addNewLink = async () => {
        setNewLink(!newLink);
        const response = await fetch('https://oldapi.caigo.org/api/dash/appearance/addLogo', {
            method: "POST",
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                serverID: selectedServer.serverID,
                logoURL: link
            })
        });

        const data = await response.json();

        if (data.success) {
            setLink("");
            getInfo();
        }
    };

    const changeTheme = async (t) => {
        const response = await fetch('https://oldapi.caigo.org/api/dash/appearance/addTheme', {
            method: "POST",
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                serverID: selectedServer.serverID,
                newTheme: t
            })
        });

        const data = await response.json();

        if (data.success) {
            getInfo();
        }
    };

    return (
        <div className="w-full h-[calc(100vh-60px)] bg-[#151A26] p-2 sm:p-10">
            <div className="flex flex-col gap-10">
                <p className="text-white">Current Logo</p>
                <img src={logoLink} alt="" className="w-32" />
                {
                    newLink
                        ?
                        <div>
                            <input type="text" className="bg-[#1d2638] text-white p-1 font-normal" onChange={(e) => setLink(e.target.value)} value={link} />
                            <div className="flex gap-2 text-white font-medium">
                                <button className="text-green-500" onClick={() => addNewLink()}>Confirm add new logo</button>
                                <button onClick={() => setNewLink(!newLink)}>Cancel</button>
                            </div>
                        </div>
                        :
                        <button className="bg-green-500 px-2 py-1 rounded text-white font-medium w-fit" onClick={() => setNewLink(!newLink)}>Add new logo</button>
                }
            </div>
            <div className="flex flex-col gap-5 mt-10">
                <p className="text-white">Current Theme</p>
                <div className="w-72">
                    <Listbox value={theme} onChange={(e) => changeTheme(e)}>
                        <div className="relative mt-1">
                            <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                                <span className={`block truncate text-[${theme.color}]`}>{theme?.name}</span>
                                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                    <ChevronUpDownIcon
                                        className="h-5 w-5 text-gray-400"
                                        aria-hidden="true"
                                    />
                                </span>
                            </Listbox.Button>
                            <Transition
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                    {themes?.map((t, ix) => (
                                        <Listbox.Option
                                            key={ix}
                                            className={({ active }) =>
                                                `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-[#d2d4ef]' : ''
                                                }`
                                            }
                                            value={t}
                                        >
                                            {({ selected }) => (
                                                <>
                                                    <span
                                                        className={`block truncate text-[${t.color}] ${theme ? 'font-medium' : 'font-normal'
                                                            }`}
                                                    >
                                                        {t?.name}
                                                    </span>
                                                    {selected ? (
                                                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                        </span>
                                                    ) : null}
                                                </>
                                            )}
                                        </Listbox.Option>
                                    ))}
                                </Listbox.Options>
                            </Transition>
                        </div>
                    </Listbox>
                </div>
            </div>
        </div>
    );
}